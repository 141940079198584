$(document).ready(function() {
	$('#main-content').fullpage({
		anchor: ['first-section', 'second-section', 'third-section'],
		responsiveWidth: 1100,
		navigation: true,
		navigationPosition: 'right'
	});

    var player = document.getElementById("audio");
    $("#play").click(function() {
        if (player.paused) {
            player.play();
            $("#play").find(".fa-play-circle-o").toggleClass("fa-play-circle-o fa-pause-circle-o");
            setVolume(1);
        } else {
            player.pause();
            $("#play").find(".fa-pause-circle-o").toggleClass("fa-pause-circle-o fa-play-circle-o");
            setVolume(0);
        }
    });
    player.addEventListener("timeupdate", updateProgress, false);

    function updateProgress() {
        var progress = $("#progressin");
        var value = 0;
        if (player.duration == "Infinity") {
            value = 100;
        } else if (player.currentTime > 0) {
            value = Math.floor(100 / player.duration * player.currentTime);
        }
        progress.stop().css({
            width: value + "%"
        }, 500);
        $("#time").html(formatTime(player.currentTime));
    }

    initAudioVolumeControl();

    $(".audio").trigger("play");

    $('#play-old').on('click', function(e) {
        e.preventDefault();
        $('#audio').attr('src', '//149.56.38.172:9882/;').trigger('play');
    });

    $('#play-new').on('click', function(e) {
        e.preventDefault();
        $('#audio').attr('src', '//149.56.38.172:9894/;').trigger('play');
    });
});

$(function() {
    $( ".schedule-weekdays" ).click(function(e) {
        e.preventDefault();
        $('#schedulelist-weekend').hide();
        $('#schedulelist-weekday').show();
    });
    $( ".schedule-weekends" ).click(function(e) {
        e.preventDefault();
        $('#schedulelist-weekday').hide();
        $('#schedulelist-weekend').show();
    });
});

function formatTime(seconds) {
    minutes = Math.floor(seconds / 60);
    minutes = minutes >= 10 ? minutes : "" + minutes;
    seconds = Math.floor(seconds % 60);
    seconds = seconds >= 10 ? seconds : "0" + seconds;
    return minutes + ":" + seconds;
}

function initHourConter() {
    new Date($.now());
}

function initAudioVolumeControl() {
    var $aud = $("#audio"), $pp = $("#playpause"), $vol = $("#volume"), $bar = $("#progressbar"), AUDIO = $aud[0];
    AUDIO.volume = 0.75;
    AUDIO.addEventListener("timeupdate", progress, false);
    function getTime(t) {
        var m = ~~(t / 60), s = ~~(t % 60);
        return (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);
    }
    function progress() {
        $bar.slider("value", ~~(100 / AUDIO.duration * AUDIO.currentTime));
        $pp.text(getTime(AUDIO.currentTime));
    }
    $vol.slider({
        value: AUDIO.volume * 100,
        slide: function(ev, ui) {
            $vol.css({
                background: "hsla(180," + ui.value + "%,50%,1)"
            });
            AUDIO.volume = ui.value / 100;
            setVolume(AUDIO.volume);
        }
    });
    $bar.slider({
        value: AUDIO.currentTime,
        slide: function(ev, ui) {
            AUDIO.currentTime = AUDIO.duration / 100 * ui.value;
        }
    });
    $pp.click(function() {
        return AUDIO[AUDIO.paused ? "play" : "pause"]();
    });
}
